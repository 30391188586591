import { Amplify } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import { Header } from "./Layout/Auth/Header";
import { Footer } from "./Layout/Auth/Footer";
import { SignInHeader } from "./Layout/Auth/SignInHeader";
import { SignInFooter } from "./Layout/Auth/SignInFooter";
import "./styles.css";
import Embed from './Embed';

import * as React from 'react';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FitScreenRoundedIcon from '@mui/icons-material/FitScreenRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Fab from '@mui/material/Fab';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

export function App({ signOut, user }) {

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const fquick = useFullScreenHandle();
  const fstudi = useFullScreenHandle();

  const fabStyle = {
    right: 20,
    bottom: 20,
    position: 'fixed'
};

const fabStyleDown = {
  right: 20,
  bottom: 90,
  position: 'fixed'
};


  const downloadFile = () => {
    window.location.href = "https://mscore.bonehealth.ai/test.xlsx"
  }

const redTheme = createTheme({ palette: { primary:{
  main: '#f50057',
  // dark: will be calculated from palette.primary.main,
  // contrastText: will be calculated to contrast with palette.primary.main
}  }})

const GreenTheme = createTheme({ palette: { primary:{
  main: '#357a38',
  // dark: will be calculated from palette.primary.main,
  // contrastText: will be calculated to contrast with palette.primary.main
}  }})

  return (
    
    <Box sx={{ width: '100%', typography: 'h3' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="tabs" centered>
            <Tab label="Analisi" value="1" />
            <Tab label="Studi" value="2" />
            <Tab label="SignOut" value="3" onClick={signOut}/>
          </TabList>
        </Box>
        <TabPanel value="1"><div>
        <ThemeProvider theme={redTheme}>
        <Fab style={fabStyle} color="primary" aria-label="fitresolution" onClick={fquick.enter}>
        <FitScreenRoundedIcon />
      </Fab>
      </ThemeProvider>
      <ThemeProvider theme={GreenTheme}>
      <Fab style={fabStyleDown} color="primary" aria-label="download" onClick={downloadFile}>
        <DownloadRoundedIcon />
      </Fab>
      </ThemeProvider>  
      <FullScreen handle={fquick}><Embed /></FullScreen>
    </div></TabPanel>
        <TabPanel value="2"><div>
        <ThemeProvider theme={redTheme}>
        <Fab style={fabStyle} color="primary" aria-label="fitresolution" onClick={fstudi.enter}>
        <FitScreenRoundedIcon />
      </Fab>
      </ThemeProvider>
      <FullScreen handle={fstudi}><iframe src="http://87.27.210.151/LPW/" frameborder="0" title="logipacs" height="90%" width="100%"></iframe></FullScreen>

    
    </div></TabPanel>
       
      </TabContext>
    </Box>
    
  );
}

export default withAuthenticator(App, {
  components: {
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    Footer
  }
});