import { Heading, useTheme, Flex } from "@aws-amplify/ui-react";

export function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Flex justifyContent="center" >
    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
      M-Score Research Platform
    </Heading>
    </Flex>
  );
}