/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:651d7bb3-1f6b-49a0-a10c-cc79f4a583cc",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_g7r3DmNep",
    "aws_user_pools_web_client_id": "40aj6s9be908f2o9o50o7udmai",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "quicksight",
            "endpoint": "https://1q4i8gm72h.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
